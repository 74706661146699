<template>
  <v-card>
    <v-card-title>入店記録</v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col class="text-center" cols="auto">
          時刻は
          <font color="success" size="7">{{ currentTime }}</font
          >です。 <br />おはようございます!
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-center" cols="auto">
          調子はどうですか？
          <br />アイコンをクリックしてください。
        </v-col>
      </v-row>
      <sentiment-picker @save="onSentimentPickerSave"></sentiment-picker>
    </v-card-text>
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

import { mapState, mapGetters } from 'vuex'

import { createStaffIfRequired, addSentiment } from '@/helpers/firebase-functions'

import sentimentPicker from '@/components/staff/sentimentPicker'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    sentimentPicker
  },
  data: () => ({
    now: null,
    ownerId: '',
    ownerScenarioId: '',
    staffId: ''
  }),
  computed: {
    currentDate () {
      return format(this.now, 'yyyy/MM/dd', { locale: ja })
    },
    currentTime () {
      return format(this.now, 'HH:mm', { locale: ja })
    },
    ...mapState('auth', ['providerData', 'uid']),
    ...mapGetters('auth', ['profile']),
    telno () {
      return this.providerData[0].phoneNumber
    }
  },
  methods: {
    async onSentimentPickerSave (sentimentIndex) {
      await addSentiment({
        ownerId: this.ownerId,
        ownerScenarioId: this.ownerScenarioId,
        staffId: this.uid,
        data: { in: true, index: sentimentIndex }
      })
      this.$router.replace(`/staff-sentiment/${this.ownerId}`)
    }
  },
  async created () {
    this.now = new Date()
    this.ownerId = this.$route.params.ownerId
    this.ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    this.staffId = this.uid
    await createStaffIfRequired({
      ownerId: this.ownerId,
      ownerScenarioId: this.ownerScenarioId,
      staffId: this.staffId,
      data: { name: this.profile.givenName.concat(this.profile.firstName), telno: this.telno }
    })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_STAFF_ENTER)
  }
}
</script>
